<template>
  <div style="position: relative; padding-top: 50px">
    <v-dialog
      v-model="dialog_integrations"
      width="90%"
      style="min-height: 700px; border-radius: 30px"
    >
      <div
        class="pa-10"
        style="height: 100%; border-radius: 20px; background-color: #fbfbfb"
      >
        <h3 class="text-h5 text-center">
          Enter the correct details to create your new account!
        </h3>
        <div class="pa-10">
          <integration-list
            @select-integration="integrationSelected"
          ></integration-list>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="dialog_add_account"
      width="900px"
      style="min-height: 700px; border-radius: 30px"
    >
      <div
        class="pa-10"
        style="height: 100%; border-radius: 20px; background-color: #fbfbfb"
      >
        <div class="pa-10">
          <add-account :account="selectedAccount"></add-account>
        </div>
      </div>
    </v-dialog>
    <v-layout row wrap class="d-flex justify-center">
      <v-flex
        xs12
        md4
        class="elevation-5 pa-5 white"
        style="border-radius: 10px"
      >
        <div class="text-center">
          <center>
            <div
              style="
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin-top: -75px;
              "
              class="elevation-5 white"
            >
              <img src="@/assets/avatar.png" alt="" style="width: 100%" />
            </div>
          </center>

          <p class="mt-3">Overview</p>
        </div>
        <div class="d-flex gap-2 justify-space-between align-center white">
          <div class="text-center">
            <h1 style="font-family: 'Poppins'">16724</h1>
            <p class="ma-0">Total Transactions</p>
            <v-icon color="teal">mdi-plus</v-icon>
          </div>
          <div
            class="pa-3"
            style="border-radius: 10.164px; background: rgba(55, 211, 89, 0.13)"
          >
          <div v-if="PNL_TOTAL == 0">
             
             <h2 style="font-family: 'Poppins'; color: #008b38;text-align: center;">
               &#8377; 0
             </h2>
           </div>
            <div v-else-if="PNL_TOTAL > 0">
             
              <h2 style="font-family: 'Poppins'; color: #008b38;text-align: center;">
                +&#8377; {{
                  PNL_TOTAL.toFixed(2)
                }}
              </h2>
            </div>
            <div v-else>
              <h2 style="font-family: 'Poppins'; color: #ef0000;text-align: center;">
                -&#8377; {{ PNL_TOTAL.toFixed(2) }}
              </h2>
            </div>
            <!-- <h1 style="color: #31b431;font-family: 'Poppins';text-align: center;">	&#8377;0</h1> -->
            <p class="ma-0">Total Account Income</p>
          </div>
          <div class="text-center">
            <h1 style="font-family: 'Poppins'">01</h1>
            <p class="ma-0">Imported Files</p>
            <v-icon color="teal">mdi-file</v-icon>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <v-container class="mt-10 pt-10 pb-10 mb-10">
      <v-layout row wrap>
        <v-flex xs12 md12 class="mt-10">
          <center>
            <v-text-field
              single-line
              outlined
              rounded
              style="max-width: 700px"
              prepend-inner-icon="mdi-magnify"
              label="Search your account"
            ></v-text-field>
          </center>
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <div
            class="d-flex gap-2 justify-space-between pa-5 elevation-2 white"
            style="border-radius: 8px"
            v-if="TRANSACTIONS[0]"
          >
            <div>
              <img
                :src="getExchangeLogoUrl(TRANSACTIONS[0].exchange)"
                style="width: 32px"
              />
            </div>
            <div class="ml-4">
              <p class="ma-0 pt-1">
                <b>{{ TRANSACTIONS[0].exchange }}</b>
              </p>
              <p class="pt-5">
                <v-icon>mdi-swap-horizontal</v-icon>{{ TRANSACTIONS.length }}
              </p>
            </div>
            <div>
              <p
                style="
                  background: rgba(61, 219, 124, 0.31);
                  border-radius: 20px;
                  font-size: 10pt;
                "
                class="pa-1 pl-5 pr-5 mt-1"
              >
                Data Synced
              </p>
            </div>
            <div class="pt-1">
              <v-icon>mdi-plus</v-icon>
            </div>
          </div>
        </v-flex>
        <!-- <v-flex xs12 md4 class="pa-2">
          <div class="d-flex gap-2 justify-space-between pa-5 elevation-2 white" style="border-radius: 8px;">
            <div>
              <img src="@/assets/coin-dcx.png" style="width: 32px" />
            </div>
            <div class="ml-4">
              <p class="ma-0 pt-1"><b>Coin DCX</b></p>
              <p class="pt-5"><v-icon>mdi-swap-horizontal</v-icon> 100</p>
            </div>
            <div>
              <p
                style="
                  background: rgba(61, 219, 124, 0.31);
                  border-radius: 20px;font-size: 10pt;
                "
                class="pa-1 pl-5 pr-5 mt-1"
              >
                Data Synced
              </p>
            </div>
            <div class="pt-1">
              <v-icon>mdi-plus</v-icon>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md4 class="pa-2" @click="ethTransactions">
          <div class="d-flex gap-2 justify-space-between pa-5 elevation-2 white" style="border-radius: 8px;">
            <div>
              <img src="@/assets/ethereum.png" style="width: 32px" />
            </div>
            <div class="ml-4">
              <p class="ma-0 pt-1"><b>Ethereum</b></p>
              <p class="pt-5" style="font-family: 'Poppins';"><v-icon>mdi-swap-horizontal</v-icon> 308</p>
            </div>
            <div>
              <p
                style="
                  background: rgba(61, 219, 124, 0.31);
                  border-radius: 20px;font-size: 10pt;
                "
                class="pa-1 pl-5 pr-5 mt-1"
              >
                Data Synced
              </p>
            </div>
            <div class="pt-1">
              <v-icon>mdi-plus</v-icon>
            </div>
          </div>
        </v-flex> -->
      </v-layout>
    </v-container>

    <div
      class="pa-5 elevation-3 d-flex gap-2 align-center justify-center pl-10 pr-10"
      style="
        position: fixed;
        bottom: 50px;
        right: 50px;
        background-color: #fff;
        border: 1px solid #4aabab;
        border-radius: 10px;
        z-index: 99;
      "
    >
      <div
        style="
          width: 48px;
          height: 48px;
          background-color: #4aabab;
          border-radius: 25px;
        "
        class="d-flex align-center justify-center"
      >
        <v-icon style="font-size: 32px" color="white">mdi-plus</v-icon>
      </div>

      <p class="ma-0 ml-5" @click="dialog_integrations = true">
        Add a new account
      </p>
    </div>
  </div>
</template>

<script>
import IntegrationList from "./IntegrationList.vue";
import AddAccount from "./AddAccount.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog_integrations: false,
      dialog_add_account: false,
      selectedAccount: {},
    };
  },
  computed: {
    ...mapGetters(["TRANSACTIONS","PNL_TOTAL"]),
  },
  components: {
    IntegrationList,
    AddAccount,
  },
  methods: {
    integrationSelected(data) {
      (this.selectedAccount = data), (this.dialog_integrations = false);
      this.dialog_add_account = true;
    },
    ethTransactions() {
      this.$router.push("/wallet-transactions");
    },
    getExchangeLogoUrl(exchangeName) {
      return require(`@/assets/icons/${exchangeName
        .toLowerCase()
        .replace(" ", "")}.png`);
    },
  },
};
</script>

<style scoped>
.v-dialog {
  background: #fff !important;
  min-height: 700px !important; /* Adjust the value as needed */
}
.v-dialog:not(.v-dialog--fullscreen) {
  background: #fff !important;
  min-height: 700px !important; /* Adjust the value as needed */
}
</style>