<template>
  <div>
    <v-layout row wrap class="mt-10">
      <v-flex xs12 class="d-flex justify-center">
        <v-text-field
          rounded
          single-line
          outlined
          label="eg. Binance, Wazir X, Ethereum"
          style="max-width: 600px"
          v-model="searchText"
        ></v-text-field>
        
      </v-flex>
   
      
      <v-layout row wrap class="pa-10 mt-5" style="border: 1px solid #E0E0E0;border-radius: 20px;background-color: #FFF;">
        <v-flex xs12 md2> 
            <p>All</p>    
            <p>Chains</p>
            <p>Exchanges</p>
            <p>Custodians</p>
            <p style="margin-bottom: 30px;">Wallets</p>
            <span style="background-color: #48AbAC;padding: 20px;margin-top: 10px;color: #FFF;" @click="customCSV">Custom CSV</span>
        </v-flex>
        <v-flex xs12 md10>
          <v-layout row wrap>
            <v-flex
              v-for="item in filteredItems"
              :key="item.title"
              xs12
              md3
              class="d-flex gap-2 align-center pa-5"
              style="border: 1px solid #eeeeee"
              @click="selectIntegration(item)"
            >
              <img :src="item.icon" style="width: 32px" />
              <p class="ma-0 ml-5">{{ item.title }}</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      items: [
        {
          icon: require("@/assets/icons/okx.png"),
          title: "OKX",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/binance.png"),
          title: "Binance",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/bitbns.png"),
          title: "Bitbns",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/coin-dcx.png"),
          title: "Coin DCX",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/wazirx.png"),
          title: "Wazir X",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/gate-io.png"),
          title: "Gate.io",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/zebpay.png"),
          title: "Zeb pay",
          type: "Exchange",
        },
        {
          icon: require("@/assets/icons/bitcoin.png"),
          title: "Bitcoin",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/ethereum.png"),
          title: "Ethereum",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/polygon.png"),
          title: "Polygon Network",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/solana.png"),
          title: "Solana",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/xrp.png"),
          title: "Ripple",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/litecoin.png"),
          title: "Litecoin",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/cardano.png"),
          title: "Cardano",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/binance.png"),
          title: "BNB Chain",
          type: "Chain",
        },
        {
          icon: require("@/assets/icons/dogecoin.png"),
          title: "Doge Coin",
          type: "Chain",
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      let query = this.searchText;
      if (query.length > 2) {
        return this.items.filter((item) =>
          item.title.toLocaleLowerCase().includes(query)
        );
      } else {
        return this.items;
      }
    },
  },
  methods: {
    connectAccount(item) {
      if (item.title == "Bitbns")
        this.$router.push({
          name: "ConnectExchangeCSV",
          params: { exchange: "Bitbns" },
        });
    },
    selectIntegration(integration){
        this.$emit("select-integration",integration)
    },
    customCSV(){this.$router.push('/upload-custom-csv')}
  },
};
</script>