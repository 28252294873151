    <template>
  <div>
    <v-text-field
      v-model="name"
      single-line
      outlined
      label="Custom Name"
    ></v-text-field>
    <div class="pl-10 pr-10 text-center">
      <p>Enter API Keys to fetch account data</p>
      <v-text-field
        v-model="name"
        single-line
        outlined
        label="API key"
      ></v-text-field>
      <v-text-field
        v-model="name"
        single-line
        outlined
        label="Secret"
      ></v-text-field>
      
      <v-btn outlined color="teal" large>Create A New Account</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  props: {
    account: {},
  },
};
</script>