<template>
    <div class="">
        <h2 class="text-center mb-5">{{ getText }}</h2>
        <div class="d-flex justify-center mb-5">
            <div style="border: 1px solid #E0E0E0;width: 400px;border-radius: 30px;" class="d-flex justify-space-between pa-1">
            <v-btn class=" pl-10 pr-10" large rounded text @click="setApiKey" :class="getClass('API')">API KEY</v-btn>
            <v-btn class="pl-10 pr-10" large rounded text @click="setCsv" :class="getClass('CSV')">CSV FILE</v-btn>
        </div>
        </div>
        
        <add-api-keys :account="account" v-if="type=='API'"></add-api-keys>
        <upload-file :account="account" v-if="type=='CSV'"></upload-file>
    </div>
</template>

<script>
import AddApiKeys from './AddApiKeys.vue';
import UploadFile from './UploadFile.vue';
export default {
    data(){
        return{
            type : "API"
        }
    },
    components : {
        AddApiKeys,UploadFile
    },
    props: {
        account : {}
    },
    computed : {
        getText(){
        if(this.type == 'API'){
            return "Enter API Keys to fetch account’s data!"
        }else if(this.type == "CSV"){
            return "Upload CSV File to fetch account’s data!"
        }
    },
    },
      
    methods: {
        setApiKey(){
            this.type = "API"
        },
        setCsv(){
            this.type = "CSV"
        },
        getClass(type){
            if(this.type == type){
                return 'teal white--text'
            }else{
                return ''
            }
        }
    }
}
</script>

