<template>
  <div>
    <div class="container">
      <div class="tabs_wrapper">
        <input
          type="radio"
          name="tabs"
          id="tab_1"
          checked
          @click="setTab('tax')"
        />
        <label class="tab" for="tab_1">
          <span class="title">Tax</span>
        </label>
        <input
          type="radio"
          name="tabs"
          id="tab_2"
          @click="setTab('transactions')"
        />
        <label class="tab" for="tab_2">
          <span class="title">Transactions</span>
        </label>
        <input
          type="radio"
          name="tabs"
          id="tab_3"
          @click="setTab('accounts')"
        />
        <label class="tab" for="tab_3">
          <span class="title">Accounts</span>
        </label>
        <span class="shape"></span>
      </div>
    </div>
    <div style="margin-top: 50px">
      <accounts v-if="tab == 'accounts'"></accounts>
      <tax v-if="tab == 'tax'"></tax>
      <transactions v-if="tab == 'transactions'"></transactions>
    </div>
  </div>
</template>


<script>
import Accounts from "../components/dashboard/Accounts.vue";
import Transactions from "../components/dashboard/Transactions.vue";
import Tax from "../components/dashboard/Tax.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "tax",
    };
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
  },
  components: {
    Accounts,
    Tax,
    Transactions,
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    if(this.USER){
      if(this.USER.name){

      }else{
        this.$router.push('/signin')
      }
    }else{
      this.$router.push('/signin')
    }
    
  },
};
</script>

<style scoped>
.title {
  font-family: "Raleway";
}
.container {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 200% 200%;
  animation: gradient 16s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.container .tabs_wrapper {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50px;
  padding: 10px;
  position: relative;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.container .tabs_wrapper .tab {
  border-radius: 50px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  width: 180px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #232323;
  user-select: none;
  transition: all 0.4s;
}
.container .tabs_wrapper .tab .material-icons {
  vertical-align: middle;
  margin-right: 3px;
  position: relative;
}
.container .tabs_wrapper .tab .title {
  vertical-align: middle;
}
.container .tabs_wrapper input {
  display: none;
}
.container .tabs_wrapper .shape {
  width: 180px;
  height: 35px;
  background: #4aabab;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 12px;
  opacity: 1;
  pointer-events: none;
  transition: all 0.5s;
}
.container .tabs_wrapper #tab_1:checked ~ .shape {
  left: 10px;
}
.container .tabs_wrapper #tab_1:checked ~ .tab[for="tab_1"] {
  z-index: 1;
  color: #ffffff;
}
.container .tabs_wrapper #tab_2:checked ~ .shape {
  left: calc(10px + 180px);
}
.container .tabs_wrapper #tab_2:checked ~ .tab[for="tab_2"] {
  z-index: 1;
  color: #ffffff;
}
.container .tabs_wrapper #tab_3:checked ~ .shape {
  left: calc(10px + 180px * 2);
}
.container .tabs_wrapper #tab_3:checked ~ .tab[for="tab_3"] {
  z-index: 1;
  color: #ffffff;
}
</style>
  